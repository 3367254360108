export class Enums{
    static DemoStatusType = [
        { "value": 0, "data": "Demo Complited" },
        { "value": 1, "data": "Demo Incomplited" }
    ];
    static DiscountType = [
        { "value": 0, "data": "PERCENT" },
        { "value": 1, "data": "FIX" }
    ];
    static ClassType = [
        { "value": 0, "data": "DEMO" },
        { "value": 1, "data": "LIVE" }
    ];
    static UserType = [
        { "value": 0, "data": "STUDENT" },
        { "value": 1, "data": "TEACHER" }
    ];
    static TrackStatus = [
        { "value": 0, "data": "PENDING" },
        { "value": 1, "data": "REJECT" },
        { "value": 2, "data": "DONE" },
    ];
    static LanguageType = [
        { "value": 0, "data": "HINDI" },
        { "value": 1, "data": "ENGLISH" },
        { "value": 2, "data": "BOTH" },
    ];
    static TeacherClassType = [
        { "value": "0", "data": "One : One" },
        { "value": "1", "data": "One : Many" },
        { "value": "2", "data": "BOTH" },
    ];
}
export enum DiscountType{
    PERCENT,FIX
}
export enum ClassType{
    DEMO,LIVE
}
export enum UserType{
    STUDENT,TEACHER,OTHER
}
export enum TrackStatus
{
    PENDING,REJECT,DONE
}
export enum LanguageType
{
    HINDI,ENGLISH,BOTH
}

